
import { Component, Vue } from 'vue-property-decorator'

@Component({ name: 'NotFound' })
export default class NotFound extends Vue {
  appName:any = process.env.VUE_APP_NAME
  appVersion:any = process.env.VUE_APP_VERSION

  mounted() {
    console.log('mounted NOT FOUND TEMPLATE');
  }
}
